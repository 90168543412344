import React, { useEffect, useState, useRef } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import axios from "axios";

import COM_FileUpload from "../Component/COM_FileUpload";

import { Button, TextField } from "@material-ui/core";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function Page_ChatRoom_Room({
  socket,
  p_room,
  p_userInfo,
  p_totalOnlineUser,
  p_RowData,
}) {
  // ------------------------------------------------- DB

  // get user info by uid
  const [previousChatLog, setPreviousChatLog] = useState([]);
  const getPreviousChatLog = (limit) => {
    console.log("p_room", p_room?.split("_")[1]);
    if (p_room != undefined) {
      axios
        .post(
          `${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__ticket_log__statusremark_byid`,
          {
            t_id: p_room?.split("_")[1],
          }
        )
        .then((res) => {
          console.log(
            "get_eclipse_awesomelist__ticket_log__InfoAll_byid",
            res.data
          );

          let messageEdit = res.data.map(
            ({
              t_id: room,
              status_by: author,
              gen_by_pic: photoURL,
              t_status_remark: message,
              status_dt: time,
              status_by_uuid: uid,
              ...rest
            }) => ({
              room,
              author,
              photoURL,
              message,
              time,
              uid,
              ...rest,
            })
          );
          console.log("messageEdit", messageEdit);

          // setMessageList((list) => [...list, ...res.data]);// ori
          setMessageList((list) => [...list, ...messageEdit]);
        });
    }
  };

  // ------------------------------------------------- Props

  const [currentMessage, setCurrentMessage] = useState("");
  const [messageList, setMessageList] = useState([]);

  const sendMessage = async () => {
    if (currentMessage !== "") {
      const messageData = {
        room: `${p_room}`,
        author: p_userInfo.u_name,
        photoURL: p_userInfo.u_pic_url,
        uid: p_userInfo.uid, // for verify is you in the chat list

        message: currentMessage,
        time: moment().format("YYYY-MM-DD HH:mm:ss"),

        // t_id: data.room,

        t_outlet: p_RowData.t_outlet,
        t_category: p_RowData.t_category,
        t_option: p_RowData.t_option,
        t_option2: p_RowData.t_option2,

        t_remark: p_RowData.t_remark,
        t_uuid: p_RowData.t_uuid,

        gen_by: p_RowData.gen_by,
        gen_by_uuid: p_RowData.gen_by_uuid,
        gen_by_pic: p_RowData.gen_by_pic,
        gen_dt: p_RowData.gen_dt,

        t_status: p_RowData.t_status,
        // t_status_remark: data.message,
        // status_by: data.author,
        // status_by_uuid: data.uid,
        status_by_pic: p_RowData.status_by_pic,
        file_att_url: selected_File_Att_Url,
      };

      await socket.emit("send_message", messageData);

      setMessageList((list) => [...list, messageData]);
      setCurrentMessage("");

      FileAtt_UUID__getNew();
    }
  };

  // --------------------------------------- Global Variable

  const [selected_File_Att_Url, setSelected_File_Att_Url] = useState("");

  const [selected_UUID, setSelected_UUID] = useState("");

  const FileAtt_UUID__getNew = () => {
    setSelected_UUID(uuidv4().replaceAll("-", ""));
  };
  const FileAtt_UUID__reset = () => {
    setSelected_UUID("");
  };

  useEffect(() => {
    getPreviousChatLog(100);
    FileAtt_UUID__getNew();
    console.log("p_RowData", p_RowData);
  }, []);

  useEffect(() => {
    socket.on("serverReply__received_message", (data) => {
      console.log("#serverReply__received_message", data);
      setMessageList((list) => [...list, data]);
    });
  }, [socket]);

  return (
    <>
      {/* <div>{`Current Status : ${p_RowData.t_status}`}</div> */}

      <div className="chat-window">
        <div className="chat-header">
          <div style={{ color: "#000", display: "flex" }}>
            <div>{`Live Chat | Room ID : ` + p_room} </div>
            <div style={{ color: "#8a8a8a" }}>
              {" "}
              {`- (${p_totalOnlineUser})`}
            </div>
          </div>
        </div>
        <div className="chat-body">
          <ScrollToBottom className="message-container">
            {messageList.map((messageContent) => {
              return (
                <>
                  <div
                    className="message"
                    id={p_userInfo.uid === messageContent.uid ? "you" : "other"}
                  >
                    <div className="ChatBox_FlexColumn">
                      <div className="ChatBox_FlexRow">
                        <img
                          className="ChatBox_ProfilePic"
                          // style={{
                          //   borderRadius: "30px",
                          //   height: "25px",
                          //   marginRight: "5px",
                          // }}
                          src={messageContent.photoURL}
                        />
                        <div className="ChatBox_AuthorText">{`${messageContent.author}`}</div>
                        <div className="ChatBox_DatetimeText">
                          {`${messageContent.time}`}
                        </div>
                      </div>

                      <div className="ChatBox_SingleMessage_Container ChatBox_FlexColumn">
                        {messageContent.file_att_url != null &&
                        messageContent.file_att_url?.includes(".jpg") ==
                          true ? (
                          <img
                            onClick={() =>
                              window.open(
                                `${
                                  messageContent.file_att_url
                                }?dummy=${Math.random()}`
                              )
                            }
                            className="ChatBox_AttPic"
                            // src={messageContent.file_att_uuid}
                            src={`${
                              messageContent.file_att_url
                            }?dummy=${Math.random()}`}
                            onerror="this.onerror=null; this.src='Default.jpg'"
                            alt=""
                          />
                        ) : (
                          <></>
                        )}

                        {messageContent.file_att_url != null &&
                        messageContent.file_att_url?.includes(".mp4") ==
                          true ? (
                          <video width="400" controls>
                            <source
                              src={messageContent.file_att_url}
                              type="video/mp4"
                              onerror="this.onerror=null; this.src='Default.jpg'"
                              alt=""
                            />
                            Your browser does not support HTML video.
                          </video>
                        ) : (
                          <></>
                        )}

                        {messageContent.message}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </ScrollToBottom>
        </div>
        <div className="ChatBox_Comment_Container">
          <TextField
            fullWidth
            autoFocus
            type="text"
            value={currentMessage}
            placeholder="reply message..."
            InputLabelProps={{
              shrink: true,
              style: {
                fontSize: 20,
                color: "#ffffff",
                // backgroundColor: "black",
              },
            }}
            inputProps={{
              shrink: true,
              style: {
                fontSize: 30,
                color: "gold",
                // backgroundColor: "black",
              },
            }}
            onChange={(event) => {
              setCurrentMessage(event.target.value);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                if (currentMessage != "") {
                  sendMessage();
                  FileAtt_UUID__getNew();
                  setSelected_File_Att_Url("");
                } else {
                  alert("Message cant be empty");
                }
              }
            }}
          />
          <Button
            onClick={() => {
              if (currentMessage != "") {
                sendMessage();
                FileAtt_UUID__getNew();
                setSelected_File_Att_Url("");
              } else {
                alert("Message cant be empty");
              }
            }}
            style={{ color: "gold", fontSize: "30px" }}
          >
            &#9658;
          </Button>
        </div>
        <div>{`file name postfix : ${selected_UUID}`}</div>
        <div className="ChatBox_FlexRow">
          {selected_File_Att_Url.includes(".mp4") == false ? (
            <COM_FileUpload
              uploadAllow={true}
              maxSizeMB="0.5"
              maxWidthOrHeight="1024"
              buttonName_PostFix={"PHOTO 1 ( Jpg Only ) "}
              serverDirPath={"uploads/AwesomeList/ticket_attachment"}
              uploadFileName={selected_UUID + "__1"}
              displayImgHeight="200px"
              fileTpe="jpg"
              c_getFileFullUrl={(value) => setSelected_File_Att_Url(value)}
            />
          ) : (
            <>Temp Hide Upload JPG</>
          )}
          {selected_File_Att_Url.includes(".jpg") == false ? (
            <COM_FileUpload
              uploadAllow={true}
              maxSizeMB="10.5"
              maxWidthOrHeight="1024"
              buttonName_PostFix={"Video 1 ( Mp4 Only ) "}
              serverDirPath={"uploads/AwesomeList/ticket_attachment"}
              uploadFileName={selected_UUID + "__1"}
              displayImgHeight="200px"
              fileTpe="mp4"
              c_getFileFullUrl={(value) => setSelected_File_Att_Url(value)}
            />
          ) : (
            <>Temp Hide Upload MP4</>
          )}
        </div>
        {selected_File_Att_Url}
      </div>
    </>
  );
}
