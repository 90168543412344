import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";

// import firebase from "firebase";

import COM_ChatRoom_Room from "./COM_ChatRoom_Room";
import moment from "moment";
import axios from "axios";

import "../Css/ChatRoom.css";

import { Grid, Button } from "@material-ui/core";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

// const socket = io.connect("http://localhost:8000");
const socket = io.connect(REACT_APP_ECLIPSE_API_PUBLIC); //"https://eclipsepubapi.freemyip.com"

export default function Page_ChatRoom_Lobby({
  p_roomID,
  p_userInfo,
  p_RowData,
}) {
  useEffect(() => {
    console.log("userInfo", p_userInfo);

    joinRoom_Auto(p_userInfo.u_name, p_roomID);
  }, []);

  const [p_totalOnlineUser, setTotalOnlineUser] = useState("0");

  // -------------------------------------------------  Chat room

  const [showChat, setShowChat] = useState(false);

  const joinRoom_Auto = (_username, _room) => {
    if (_username !== "" && _room !== "") {
      socket.emit("join_room", _room); // join room
      setShowChat(true);
    }
  };

  useEffect(() => {
    socket.on("broadcast", (data) => {
      // console.log("#broadcast", data);
      setTotalOnlineUser(data);
    });
    socket.on("res_change_yt_rul", (data) => {
      // console.log("#res_change_yt_rul", data);
    });
  }, [socket]);

  return (
    <div>
      {!showChat ? (
        <>ShowChat Failed!</>
      ) : (
        <>
          <COM_ChatRoom_Room
            socket={socket}
            p_room={p_roomID}
            p_userInfo={p_userInfo}
            p_totalOnlineUser={p_totalOnlineUser}
            p_RowData={p_RowData}
          />
        </>
      )}
    </div>
  );
}
