import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import COM_FileUpload from "../Component/COM_FileUpload";
import COM_ChatRoom_Lobby from "../Component/COM_ChatRoom_Lobby";

import COM_NavBar_Global from "../Component/COM_NavBar_Global";

//  -------------------------- mantine
import {
  RadioGroup,
  Radio,
  Autocomplete,
  TextInput,
  Button,
  Modal,
  Group,
  Checkbox,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Refresh, FileSearch, Check, ArrowBigRight } from "tabler-icons-react";
import { NotificationsProvider } from "@mantine/notifications";
import { showNotification, updateNotification } from "@mantine/notifications";

//  -------------------------- Material Table
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

import { UserContext } from "../UserContext";
import { Zoom } from "@mui/material";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function Page_ESLOneRankingBoard(props) {
  // const { match } = props;
  // const { params } = match;
  // const { Id } = params;

  // ------------------------------------------------- DB

  // all ticket record - pending and solving
  const [
    DB_ticket_log_all_PendingSolving,
    setDB_ticket_log_all_PendingSolving,
  ] = useState([]);
  const get_DB_ticket_log_all_PendingSolving = () => {
    axios.post(`${REACT_APP_ECLIPSE_API}/get_ESL_RankingBoard`).then((res) => {
      setDB_ticket_log_all_PendingSolving(res.data[1]);
      console.log("# get_DB_ticket_log_all_PendingSolving", res.data[1]);
    });
  };

  const myID = props?.match?.params?.id;

  // ------------------------------------------------- Table Columns

  // const columns = [
  //   {
  //     title: "c_id",
  //     field: "c_id",
  //     width: "1px",
  //   },
  //   {
  //     title: "c_name",
  //     field: "c_name",
  //     width: "1px",
  //   },
  //   {
  //     title: "total_duration",
  //     field: "total_duration",
  //     width: "1px",
  //   },
  // ];

  useEffect(() => {
    get_DB_ticket_log_all_PendingSolving();
    console.log("props", props?.match?.params?.id);
    console.log("myID", myID);
  }, []);

  return (
    <div>
      <>
        <div className="table_container">
          <table className="table_setting">
            <tr className="">
              <th className="table_txt_bold">RANK</th>
              <th className="table_txt_bold">NAME</th>
              <th className="table_txt_bold">PTS</th>
            </tr>

            {DB_ticket_log_all_PendingSolving?.slice(0, 18).map((x) => (
              <tr className={x?.c_name == myID ? "table_selected" : ""}>
                <th className="table_txt_normal">{`${x?.Ranking}`}</th>
                <th className="table_txt_normal flexRow">
                  <div>{`${x?.c_name} `}</div>
                  {x?.c_login_cafe.toLowerCase().includes("gift") ? (
                    <div className="table_text_gift">{`${x?.c_login_cafe.replace(
                      "_",
                      " "
                    )}`}</div>
                  ) : (
                    <div className="table_text_redsea">{`${x?.c_login_cafe.replace(
                      "_",
                      " "
                    )}`}</div>
                  )}
                </th>
                <th className="table_txt_normal">{`${x?.total_pts.toFixed(
                  3
                )}`}</th>
              </tr>
            ))}
          </table>
          <br />
          <br />

          <table className="table_setting">
            <tr>
              <th className="table_txt_bold">RANK</th>
              <th className="table_txt_bold">NAME</th>
              <th className="table_txt_bold">PTS</th>
            </tr>

            {DB_ticket_log_all_PendingSolving?.slice(18, 36).map((x) => (
              <tr className={x?.c_name == myID ? "table_selected" : ""}>
                <th className="table_txt_normal">{`${x?.Ranking}`}</th>
                <th className="table_txt_normal flexRow">
                  <div>{`${x?.c_name} `}</div>
                  {x?.c_login_cafe.toLowerCase().includes("gift") ? (
                    <div className="table_text_gift">{`${x?.c_login_cafe.replace(
                      "_",
                      " "
                    )}`}</div>
                  ) : (
                    <div className="table_text_redsea">{`${x?.c_login_cafe.replace(
                      "_",
                      " "
                    )}`}</div>
                  )}
                </th>
                <th className="table_txt_normal">{`${x?.total_pts.toFixed(
                  3
                )}`}</th>
              </tr>
            ))}
          </table>
        </div>
        <div className="table_container ">
          <div className="table_setting"></div>
          <table className="table_setting table_bg_1">
            <tr>
              <th className="table_txt_bold">RANK</th>
              <th className="table_txt_bold">NAME</th>
              <th className="table_txt_bold">PTS</th>
            </tr>

            {DB_ticket_log_all_PendingSolving?.filter((x) => {
              return x.c_name == myID;
            })?.map((x) => (
              <tr className="table_text_yellow">
                <th className="table_txt_normal">{`${x?.Ranking}`}</th>
                <th className="table_txt_normal flexRow">
                  <div>{`${x?.c_name} `}</div>
                  {x?.c_login_cafe.toLowerCase().includes("gift") ? (
                    <div className="table_text_gift">{`${x?.c_login_cafe.replace(
                      "_",
                      " "
                    )}`}</div>
                  ) : (
                    <div className="table_text_redsea">{`${x?.c_login_cafe.replace(
                      "_",
                      " "
                    )}`}</div>
                  )}
                </th>
                <th className="table_txt_normal">{`${x?.total_pts.toFixed(
                  3
                )}`}</th>
              </tr>
            ))}
          </table>
        </div>
        {/* <MaterialTable
          style={{
            MuiTablePaginationRoot: "gold",
            color: "white",
            // background: "rgba(0, 0, 100, .0)",
            backgroundImage:
              "linear-gradient(to bottom right, #ffd900, #ffa90a)",
            boxShadow: "0 8px 32px 0 rgba( 0, 0, 0, 0.4 )",
            backdropFilter: "blur( 11.0px )",
            "&::webkit-backdrop-filter": {
              webkitBackdropFilter: "blur( 18.0px )",
            },
          }}
          title={<div className="flexRow">ESL Top24 </div>}
          columns={columns}
          data={DB_ticket_log_all_PendingSolving}
          options={{
            headerStyle: {
              background: "rgba(255, 255, 255, .2)",
              border: "none",
            },
            exportButton: true,
            rowStyle: (rowData) => ({
              backgroundColor: "#1a0000",
              color: "#ffffff",
              fontSize: 12,
            }),
            filtering: true,
            // grouping: true,
            // exportAllData: true,
            pageSize: 100,
            pageSizeOptions: [200, 500, 1000],
            paginationType: "stepped",
            // actionsColumnIndex: -1
            // fixedColumns: {
            //     left: 1,
            // }
            // exportMenu: [
            //   {
            //     label: "Export PDF",
            //     exportFunc: (cols, datas) =>
            //       ExportPdf(cols, datas, "myPdfFileName"),
            //   },
            //   {
            //     label: "Export CSV",
            //     exportFunc: (cols, datas) =>
            //       ExportCsv(cols, datas, "myCsvFileName"),
            //   },
            // ],
          }}
          // onRowClick={(evt, selectedRow) => {
          // alert(selectedRow.id);
          // }}
          // actions={[
          //   {
          //     icon: "save",
          //     tooltip: "Save User",
          //     onClick: (event, rowData) => {
          //       // handleClickOpen_1_2();
          //       // getDB_patroninfo_byid(rowData.id);
          //       // alert(rowData.id);
          //     },
          //   },
          // ]}
          components={{
            Action: (props) => (
              <>
                {/* <Button
              classes={{ root: classes.button }}
              onClick={(event) => props.action.onClick(event, props.data)}
              >
                <div style={{ color: "gold" }}>DONE</div>
              </Button>
              <Button
              classes={{ root: classes.button }}
              onClick={(event) => props.action.onClick(event, props.data)}
              >
                <div style={{ color: "#2bc3ff" }}>EDIT</div>
              </Button> 
              </>
            ),
          }}
        /> */}
      </>
    </div>
  );
}
