import { UserContext } from "./UserContext";
import { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";

// import Page_Login from "./Page/Page_Login";
// import Page_Main from "./Page/Page_Main";
// import Page_Ticket_Create from "./Page/Page_Ticket_Create";
// import Page_Ticket_MyTicket from "./Page/Page_Ticket_MyTicket";
// import Page_Ticket_DailySummit from "./Page/Page_Ticket_DailySummit";
// import Page_Ticket_Summary from "./Page/Page_Ticket_Summary";
// import Page_Setting from "./Page/Page_Setting";
// import Page_UserProfile from "./Page/Page_UserProfile";
import Page_ESLOneRankingBoard from "./Page/Page_ESLOneRankingBoard";
import Page_404 from "./Page/Page_404";
// import COM_GetVersionSync from "./Component/COM_GetVersionSync";

import "./Css/global.css";
import "./Css/CSS_NavBar.css";

// ------------------------------------------------- router
import {
  HashRouter,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";

// ------------------------------------------------- mantine
import { NotificationsProvider } from "@mantine/notifications";

//  -------------------------- Theme Provider
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { orange } from "@mui/material/colors";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

const parrent_DB_userinfo = [];
const fakeAuth = {
  isAuthenticated: false,
  authenticate(cb) {
    this.isAuthenticated = true;
    setTimeout(cb, 100);
  },
  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

function ProtectedRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return fakeAuth.isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
}

function App() {
  // --------------------------------------- use context
  const contextValue = useRef([]);

  useEffect(() => {}, []);

  return (
    <>
      <NotificationsProvider position="top-center">
        <UserContext.Provider value={{ contextValue }}>
          <HashRouter hashType="slash">
            <Switch>
              <Route
                exact
                path="/"
                component={() => <Page_ESLOneRankingBoard />}
              />
              <Route
                path="/:id"
                // path="/Page_OurBusiness_Brand_Reviews/"
                // component={Page_OurBusiness_Brand_Reviews}
                render={(props) => <Page_ESLOneRankingBoard {...props} />}
              />
              {/* <ProtectedRoute
                exact
                path="/main"
                component={() => <Page_Main />}
              />
              <ProtectedRoute
                exact
                path="/create"
                component={() => <Page_Ticket_Create />}
              />
              <ProtectedRoute
                exact
                path="/ticket"
                component={() => <Page_Ticket_MyTicket />}
              />
              <ProtectedRoute
                exact
                path="/Page_Ticket_DailySummit"
                component={() => <Page_Ticket_DailySummit />}
              />
              <ProtectedRoute
                exact
                path="/summary"
                component={() => <Page_Ticket_Summary />}
              />
              <ProtectedRoute
                exact
                path="/setting"
                component={() => <Page_Setting />}
              />
              <ProtectedRoute
                exact
                path="/userprofile"
                component={Page_UserProfile}
              /> */}

              <Route exact path="*" component={Page_404} />
            </Switch>
          </HashRouter>
        </UserContext.Provider>
        {/* <COM_GetVersionSync /> */}
      </NotificationsProvider>
    </>
  );
}

export default App;
